<template>
    <div class="sidebar-results" :class="{ 'expand': mode === 1, 'collapse': mode === 0 }" :style="{ height: currentHeight + 'px' }">
        <div class="sidebar-results__header" @touchstart="handleTouchStart($event)" @touchmove="handleTouchMove()" @touchend="handleTouchEnd()">
            <div class="sidebar-results__drag" v-if="mode !== 0"></div>
            <img src="@/assets/images/svg/up.svg" class="sidebar-results__drag sidebar-results__drag_up" v-else>
            <picture class="sidebar-results__list-img">
                <source srcset="../../assets/images/svg/list.svg" media="(min-width: 769px)">
                <source srcset="../../assets/images/svg/list_white.svg" media="(max-width: 768px)">
                <img src="@/assets/images/svg/list.svg">
            </picture>
            <h2 class="sidebar-results__heading">Отображено <span>{{ items.length }} из {{ filterCount }}</span>
                {{ layer === 'partners' ? 'партнеров' : 'объектов' }}
            </h2>
            <img src="@/assets/images/svg/close_white.svg" class="sidebar-results__close" @click.stop="pushToDefaultRoute()">
        </div>
        <ul class="sidebar-results__list">
            <SidebarListItem v-for="(item) in items" :key="item.id" :item="item" />
        </ul>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import entity from '@/helpers/FetchEntities';
import SidebarListItem from '@/components/sidebar/SidebarListItem.vue';

export default {
    name: 'SidebarResultsList',
    components: {
        SidebarListItem
    },
    data() {
        return {
            mode: 1,
            currentHeight: window.innerHeight - 189, // Начальная высота панели
            startPosition: null,
            minHeight: 55, // Минимальная высота панели
            maxHeight: window.innerHeight - 189 // Максимальная высота панели
        }
    },
    async mounted() {
        await this.handleRoute();
    },
    computed: {
        ...mapGetters('layer', ['layer']),
        ...mapGetters('filter', ['filterId', 'filterCount', 'filterValues']),
        ...mapGetters('entity', ['items']),
        ...mapGetters('map', ['bounds'])
    },
    methods: {
        async handleRoute() {
            const path = this.$route.path;

            if (path.includes('filter')) {
                await this.handleFilter(this.$route.query.c);
            } else if (path.includes('search')) {
                this.handleQuery(this.$route.query.q);
            }
        },
        async handleFilter(context) {
            if (context === null || context === undefined) {
                this.pushToDefaultRoute();
                return;
            }

            if (context !== this.filterId) {
                this.setFilterId(context);

                const actions = {
                    setEntities: this.setEntities,
                    setFilterId: this.setFilterId,
                    selectDirection: this.selectDirection,
                    setCount: this.setCount
                };

                this.startLoading();
                if (!this.bounds) {
                    const interval = setInterval(async () => {
                        if (this.bounds) {
                            clearInterval(interval);
                            await entity.filterById(this.layer, context, this.bounds, actions);
                            this.stopLoading();
                        }
                    }, 500);
                } else {
                    await entity.filterById(this.layer, context, this.bounds, actions);
                    this.stopLoading();
                }
            }
        },
        handleQuery(query) {
            if (query === null || query === undefined) {
                this.pushToDefaultRoute();
            }

            console.log('Search', query);
        },
        pushToDefaultRoute() {
            this.$router.push({ path: `/${this.layer}` });
        },
        handleTouchStart(event) {
            this.startPosition = event.touches[0].clientY;
        },
        handleTouchMove(event) {
            event.preventDefault(); // Предотвращаем стандартное поведение браузера

            const currentY = event.touches[0].clientY;
            const distance = this.mode ? currentY - this.startPosition : this.startPosition - currentY;
            const currentHeight = this.mode ? this.maxHeight - distance : this.minHeight + distance;

            if (currentHeight > this.minHeight && currentHeight < this.maxHeight) {
                this.currentHeight = currentHeight;
            }
        },
        handleTouchEnd() {
            const middle = this.maxHeight / 2;

            if (this.currentHeight > middle) {
                this.mode = 1;
                this.currentHeight = this.maxHeight;
            } else {
                this.mode = 0;
                this.currentHeight = this.minHeight;
            }

            this.startPosition = null;
        },
        ...mapActions('filter', ['setFilterId', 'setCount', 'selectDirection']),
        ...mapActions('layer', ['fetchItems']),
        ...mapActions('entity', ['setEntities']),
        ...mapActions('loader', ['startLoading', 'stopLoading'])
    }
}
</script>

<style lang="scss" scoped>
.sidebar-results {
    display: flex;
    flex-direction: column;
    transition: height .15s ease-out;
    overflow-y: hidden;

    &__header {
        background-color: #F2F2F2;
        border-top: 1px solid #BFBFBF;
        border-bottom: 1px solid #BFBFBF;
        padding-left: 8px;
        min-height: 42px;
        display: flex;
        justify-content: left;
        align-items: center;
        position: sticky;
        top: 0px;
        z-index: 1;
    }

    &__drag {
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
        width: 22px;
        height: 3px;
        background-color: rgba(255, 255, 255, .7);
        display: none;
    }

    &__drag_up {
        width: 22px;
        height: 6px;
        background-color: transparent;
    }

    &__list-img {
        width: 26px;
        height: 26px;
        margin-right: 8px;
    }

    &__heading {
        font-size: 16px;
        line-height: 22px;

        span {
            font-weight: bold;
        }
    }

    &__close {
        position: absolute;
        top: 14px;
        right: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: none;
    }

    &__list {
        background-color: #fff;
        overflow-y: auto;
        height: calc(100vh - 260px);
    }

    &__list::-webkit-scrollbar {
        width: 9px;
    }

    &__list::-webkit-scrollbar-thumb {
        background-color: black;
        border-right: 4px #fff solid;
        background-clip: padding-box;
    }
}

@media (max-width: 1023px) {
    .sidebar-results {
        position: fixed;
        bottom: 0;
        width: 100vw;

        &__header {
            background-color: #000;
            color: #fff;
            min-height: 56px;
        }

        &__heading {
            font-size: 18px;
        }

        &__drag,
        &__close {
            display: block;
        }
    }

    .sidebar-results.expand {
        overflow-y: scroll;
    }

    .sidebar-results.collapse {
        height: 55px;
    }
}
</style>
